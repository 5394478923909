@charset 'utf-8';
/* reset
------------------------------------------------------------*/
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
dialog,
figure,
footer,
header,
nav,
section,
time,
mark,
audio,
picture,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
picture,
svg,
a,
main {
  display: block;
}

body,
table,
input,
textarea,
select,
option,
h1,
h2,
h3,
h4,
h5,
h6 {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
}

table,
input,
textarea,
select,
option {
  line-height: 1.1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

:focus {
  outline: 0;
}

ins {
  text-decoration: none;
}

del {
  text-decoration: line-through;
}

img {
  vertical-align: top;
}

a {
  color: inherit;
  text-decoration: none;
}

a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

a,
a img {
  transition: 0.3s ease-in-out;
}

a:hover,
a:hover img {
  opacity: 0.8;
  filter: alpha(opacity=80);
}

figure,
picture {
  width: 100%;
  height: 100%;
}

img {
  max-width: 100%;
}
