.mainvisualContainer {
  position: relative;
}

.mainvisualImg {
  width: 100%;
  height: 100vh;
  background-color: #33392e;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media only screen and (max-width: 1023px) {
    height: var(--app-height);
  }
}

.mainvisualLogo {
  position: absolute;
  right: 35px;
  bottom: 35px;
  z-index: 1;

  @media only screen and (max-width: 1023px) {
    right: auto;
    left: 22px;
    bottom: 22px;

    svg {
      width: 72.92px;
      height: auto;
    }
  }
}
