.loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  height: var(--app-height);
  background-color: #f0f0e4;
  overflow: hidden;
  z-index: 99999;
  transition: all 1.5s ease-out;

  &.hide {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
  }

  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    position: relative;

    .name svg {
      width: auto;
      height: 25px;
    }

    .line {
      position: absolute;
      bottom: 50px;

      width: 80px;
      height: 2px;
      background-color: rgba($color: #5a7445, $alpha: 0.3);
      overflow: hidden;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 0%;
        height: 100%;
        background-color: #5a7445;
        animation: loading 2s forwards;
        animation-delay: 0.5s;
      }
    }

    @media only screen and (max-width: 1023px) {
      .line {
        bottom: 45px;
        width: 60px;
      }
    }

    @keyframes loading {
      0% {
        width: 0%;
      }
      100% {
        width: 100%;
      }
    }
  }
}
