@import 'src/styles/imports/mixins';

.companyContainer {
  margin: 150px auto 150px;
  padding: 0 30px;
  display: grid;
  grid-template-columns: 21.5% 1fr 1fr;
  gap: 80px;

  @include maxW(1023px) {
    display: block;
    margin-top: 30px;
    margin-bottom: 120px;
    padding: 120px 18px 0;
  }
}

.companyLeft {
  h2 {
    margin-bottom: 30px;
    font-size: 2.1rem;
    line-height: 1.2;
    font-weight: 400;
  }

  @include maxW(1023px) {
    margin-bottom: 60px;

    h2 {
      margin-bottom: 15px;
      font-size: 1.8rem;
    }
  }
}

.companyLang {
  display: flex;
  align-items: center;
  gap: 8px;
  font-family: var(--times-fonts);

  p,
  span {
    cursor: pointer;
    font-size: 1.7rem;
    opacity: 0.3;
    transition: 0.15s linear;
    text-transform: uppercase;

    &.active {
      opacity: 1;
    }
  }

  @include maxW(1023px) {
    p,
    span {
      font-size: 1.6rem;
    }
  }
}

.companyText {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 30px;

  @include maxW(1023px) {
    font-size: 1.5rem;
    margin-bottom: 20px;
  }
}

.companyContent {
  margin-bottom: 80px;

  &:last-child {
    margin-bottom: initial;
  }

  h3 {
    margin-bottom: initial;
  }

  p,
  a {
    font-size: 1.5rem;
    line-height: 1.7;
    font-feature-settings: 'palt' 1;
  }

  a {
    text-decoration: underline;
    width: fit-content;
  }

  @include maxW(1023px) {
    margin-bottom: 60px;

    p,
    a {
      font-size: 1.3rem;
      letter-spacing: 0;
    }
  }
}

.companySubtitle {
  margin-bottom: 25px;

  font-feature-settings: 'palt' 1;

  @include maxW(1023px) {
    margin-bottom: 20px;

    p {
      font-size: 1.4rem;
    }
  }
}

.companyMember {
  @include maxW(1023px) {
    margin-bottom: 60px;
  }
}
