@import 'src/styles/imports/mixins';

.firstview {
  width: 100%;
  height: 100vh;

  position: relative;

  @include maxW(1023px) {
    height: var(--app-height);
  }
}

.firstviewContainer {
  position: absolute;
  bottom: 25px;

  @include maxW(1023px) {
    bottom: 15px;
  }
}

.firstviewHeading {
  padding: 0 18px;

  h2 {
    font-family: var(--times-fonts);
    font-size: 3vw;
    letter-spacing: -0.7px;
    font-weight: 400;
    text-align: center;
    line-height: 1.1;
  }

  span {
    font-family: var(--sans-serif-fonts);
    font-size: 2.5vw;
  }

  @include maxW(1023px) {
    h2 {
      font-size: 2.6rem;
      text-align: left;
      letter-spacing: -0.3px;
    }

    span {
      font-size: 2.3rem;
    }
  }
}
