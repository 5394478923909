@import 'src/styles/imports/mixins';

.prototype {
  @include maxW(1023px) {
    margin-top: -75px;
    padding-top: 75px;
  }
}

.prototypeContent {
  width: 100%;
  margin: 150px auto 60px;

  display: flex;
  align-items: center;
  flex-direction: row-reverse;

  p {
    font-family: var(--yugothic-fonts);
    font-weight: 500;
    font-feature-settings: 'palt' 1;

    &.en {
      font-weight: 400;
    }
  }

  .left,
  .right {
    width: 50%;
  }

  .left {
    padding-left: 16.666666666667%;

    .logo svg {
      rect,
      path {
        fill: currentColor;
      }
      line {
        stroke: currentColor;
      }
    }
  }

  .right {
    padding-right: 14.583333333333%;
  }

  @include maxW(1023px) {
    display: block;
    padding: 0 16.9082125604% 0 22px;
    margin: 45px auto;

    .left,
    .right {
      width: 100%;
      padding: 0;
    }

    .left {
      margin-top: 80px;

      .logo svg {
        width: auto;
        height: 120px;
      }
    }
  }
}

.prototypeTitle {
  position: absolute;
  bottom: 30px;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 35px;

  h2 {
    text-transform: uppercase;
    font-size: 3.6rem;
    font-weight: 400;
  }

  @include maxW(1023px) {
    position: unset;
    padding: 0;

    h2 {
      font-size: 2.4rem;
      position: absolute;
      top: 20px;
      left: 22px;
      z-index: 2;
    }
  }
}

.prototypeHead {
  h2 {
    font-size: 2.6rem;
    font-weight: 400;

    span {
      font-size: 1.6rem;
      display: inline-block;
      margin-left: 5px;
      position: absolute;
    }
  }

  p {
    margin-top: 15px;
    margin-left: -1px;
    font-size: 1.8rem;
    letter-spacing: -0.2px;
    text-align: justify;

    &.en {
      letter-spacing: -0.36px;
    }
  }

  @include maxW(1023px) {
    h2 {
      font-size: 2.2rem;

      span {
        font-size: 1.3rem;
      }
    }

    p {
      margin-top: 10px;
      font-size: 1.4rem;

      &.en {
        letter-spacing: -0.28px;
      }
    }
  }
}

.prototypeDesc {
  margin-top: 50px;

  p {
    font-size: 1.5rem;
    letter-spacing: -0.2px;
    text-align: justify;
    line-height: 1.8;
    margin-bottom: 25px;

    &:last-child {
      margin-top: 50px;
      margin-bottom: initial;
    }

    &.en {
      letter-spacing: -0.3px;
    }
  }

  @include maxW(1023px) {
    margin-top: 35px;

    p {
      font-size: 1.3rem;
      line-height: 1.75;
      margin-bottom: 20px;

      &.en {
        letter-spacing: -0.26px;
      }
    }
  }
}

.prototypeLink {
  display: flex;
  align-items: center;
  gap: 28px;
  margin-top: 15px;

  a:hover {
    opacity: 1;
  }

  @include maxW(1032px) {
    margin-top: 10px;
    gap: 18px;

    a:first-child svg {
      width: auto;
      height: 20px;
    }

    a:nth-child(2) svg {
      width: auto;
      height: 20px;
    }

    a:last-child svg {
      width: auto;
      height: 18px;
    }
  }
}

.prototypeSwiper {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  height: 100vh;
  position: relative;

  .left,
  .right {
    overflow: hidden;
    position: relative;
  }
}

.prototypeSwiperSP {
  position: relative;
}

.prototypeTotal {
  display: flex;
  align-items: center;
  gap: 10px;

  position: absolute;
  bottom: 20px;
  left: 22px;
  z-index: 2;

  span {
    font-size: 1.6rem;
  }

  .bullet {
    display: none;
  }
}

.prototypeSwiperWrapper {
  height: 100%;
}

.prototypeSlide {
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @include maxW(1023px) {
    aspect-ratio: 3 / 4;
  }
}

.prototypePagination {
  display: flex;
  align-items: center;
  gap: 20px;

  .bullet {
    opacity: 0.5;
    cursor: pointer;
    font-size: 2.4rem;
    transition: 0.3s linear;
    background: transparent;
  }

  .bulletActive {
    opacity: 1;
  }
}
