@import 'src/styles/imports/mixins';

.scrolldown {
  position: fixed;
  right: 30px;
  bottom: 30px;

  .line {
    display: block;
    height: 40px;
    width: 1px;
    background-color: rgba($color: var(--main-text-rgb), $alpha: 0.3);
    text-align: center;
    position: relative;
    overflow: hidden;
    margin: 0 auto;

    span {
      position: absolute;
      display: block;
      left: 0;
      top: 0;
      width: 100%;
      height: 0%;
      animation: scroll-down 4s infinite;
      background-color: var(--main-text-cl);
    }
  }

  @include maxW(1023px) {
    right: 20px;
    bottom: 20px;
  }
}

@keyframes scroll-down {
  0% {
    height: 0;
    top: 0;
  }
  30% {
    height: 100%;
    top: 0;
  }
  60% {
    height: 100%;
    top: 100%;
  }
  100% {
    height: 100%;
    top: 100%;
  }
}
