.footerContainer {
  padding: 0 35px 25px;
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 100;

  @media only screen and (max-width: 1023px) {
    padding: 0 22px 22px;
  }
}

.footerLeft {
  display: flex;
  align-items: flex-end;
  gap: 25px;
  font-size: 1.7rem;

  a {
    text-transform: uppercase;
    position: relative;
    display: inline-block;
    color: currentColor;

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      border-radius: 5px;
      height: 2px;
      bottom: -3px;
      left: 0;
      background: currentcolor;
      transform: scaleX(0);
      transform-origin: bottom right;
      transition: transform 0.3s ease-out;
    }

    &:hover {
      opacity: 1;

      &::after {
        transform: scaleX(1);
        transform-origin: bottom left;
        opacity: 1;
      }
    }
  }

  @media only screen and (max-width: 1023px) {
    gap: 15px;
    font-size: 1.2rem;
  }
}

.footerRight {
  cursor: pointer;
  transition: 0.3s ease-in-out;

  svg {
    margin-bottom: 5px;
  }

  line {
    stroke: currentColor;
  }

  &:hover {
    opacity: 0.8;
  }
}
