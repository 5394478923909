@import 'src/styles/imports/mixins';

.intro {
  width: 100%;
  height: 100vh;

  opacity: 0;
  visibility: hidden;

  @include maxW(1023px) {
    height: var(--app-height);
  }
}

.introContainer {
  width: 100%;
  height: 100%;
  position: relative;

  display: grid;
  grid-template-columns: 140px 1fr 170px 1fr 120px;
  align-items: flex-end;

  @include maxW(1023px) {
    display: block;
    gap: unset;
  }
}

.introLeft,
.introRight {
  width: 100%;
  height: calc(100vh - 49px);
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;

  // opacity: 0;
  // visibility: hidden;

  @include maxW(1023px) {
    width: 100%;
    height: 100%;
  }
}

.introRight {
  opacity: 0;
  visibility: hidden;
}

.introLeft {
  display: flex;
  align-items: center;

  .omoty {
    width: 100%;
    transform: translateX(calc(50% + 56.6666667px));
  }

  svg {
    width: 100%;
    height: 100%;
  }

  path,
  rect {
    fill: var(--main-text-cl);
  }

  @include maxW(1023px) {
    display: block;
    position: absolute;
    top: 0;

    .omoty {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    svg {
      margin: 0 auto;
      width: 80%;
      padding-right: 3.5%;
    }
  }
}

.introTop,
.introBottom {
  width: 100%;
  padding-left: 15px;

  @include maxW(1023px) {
    display: block;
    height: auto;
    top: auto;
    bottom: 22px;
    padding: 0 18px;
  }
}

.introTop {
  h2 {
    font-size: 3.8rem;
    font-weight: 400;
    margin-bottom: 25px;
    letter-spacing: -0.7px;
  }

  p {
    font-size: 1.8rem;
    line-height: 1.5;
    font-feature-settings: 'palt' 1;
  }

  @include minW(1441px) {
    h2 {
      font-size: 2.64vw;
    }

    p {
      font-size: 1.25vw;
    }
  }

  @include maxW(1023px) {
    h2 {
      font-size: 2.8rem;
      margin-bottom: 20px;
      letter-spacing: -0.3px;
    }

    p {
      font-size: 1.4rem;
    }
  }
}

.introBottom {
  margin-top: 55px;

  p {
    font-size: 1.5rem;
    font-feature-settings: 'palt' 1;
    line-height: 1.8;

    &:first-child {
      margin-bottom: 25px;
    }

    span {
      font-size: 1.6rem;
    }
  }

  @include minW(1441px) {
    p {
      font-size: 1.042vw;

      span {
        font-size: 1.11vw;
      }
    }
  }

  @include maxW(1023px) {
    p {
      width: 100%;
      font-size: 1.3rem;
      line-height: 1.7;

      span {
        font-size: 1.4rem;
      }
    }
  }
}
