@import 'src/styles/imports/mixins';

.projects {
  width: 100%;
  height: 100%;
}

.projectsContainer {
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  @include maxW(1023px) {
    height: var(--app-height);
  }
}

.projectsTitle {
  position: absolute;
  left: 30px;
  top: 50.5%;
  transform: translateY(-50%);

  p {
    font-size: 2.1rem;
    text-transform: uppercase;
  }

  h2 {
    font-size: 4.8rem;
    font-weight: 400;
    line-height: 1.4;
    letter-spacing: -0.5px;

    sup {
      font-size: 2.8rem;
    }
  }

  @include maxW(1023px) {
    top: auto;
    left: 18px;
    bottom: 60px;
    transform: none;

    p {
      font-size: 1.6rem;
    }

    h2 {
      font-size: 3.8rem;
      line-height: 1.3;

      sup {
        font-size: 2.2rem;
      }
    }
  }
}

.projectsImg {
  height: 68.83vh;
  margin-left: 20px;
  margin-top: 56px;
  width: auto;
  opacity: 0.75 !important;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    opacity: 1 !important;
  }

  @include maxW(1023px) {
    padding: 0 40px;
    margin-top: -30px;
    margin-left: 0;
    width: 100%;
    height: 42.37vh;
  }
}

.projectsDetail {
  position: absolute;
  left: 30px;
  bottom: 25px;

  .link {
    font-size: 1.9rem;
    text-transform: uppercase;
    position: relative;
    overflow: hidden;
    display: inline-block;
    padding-bottom: 5px;

    &:before {
      content: '';
      width: 100%;
      height: 2px;
      position: absolute;
      bottom: 0;
      left: 0;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      background-color: currentColor;
    }

    &:hover {
      opacity: 1;

      &::before {
        animation: lineLink 0.5s;
      }
    }
  }

  @include maxW(1023px) {
    left: 18px;
    bottom: 15px;

    .link {
      font-size: 1.5rem;

      &:before {
        height: 1px;
      }
    }
  }

  @keyframes lineLink {
    50% {
      left: 100%;
      animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    }
    51% {
      opacity: 0;
    }
    52% {
      left: -100%;
    }
    53% {
      opacity: 1;
    }
    100% {
      left: 0;
      animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    }
  }
}
