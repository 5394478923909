@import 'src/styles/imports/mixins';

.header {
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;

  padding: 25px 30px 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 80px;
  align-items: baseline;

  @include maxW(1023px) {
    padding: 15px 18px 0;
    display: flex;
    justify-content: space-between;
    gap: unset;
  }
}

.headerLeft {
  width: fit-content;

  h1 {
    font-size: 2.4rem;
    letter-spacing: -0.5px;
    font-weight: 400;

    @include maxW(1023px) {
      font-size: 2.2rem;
    }
  }
}

.headerCenter {
  display: flex;
  justify-content: center;
  gap: 8px;

  font-size: 1.9rem;
  text-transform: uppercase;

  p {
    cursor: pointer;
  }

  @include maxW(1023px) {
    display: none;
  }
}

.headerRight {
  display: flex;
  justify-content: flex-end;
  text-transform: uppercase;
  font-size: 1.9rem;

  label {
    pointer-events: none;
  }

  span {
    margin-right: 12px;
  }

  p {
    display: none;
  }

  .toggle {
    border-radius: 50px;
    cursor: pointer;
    position: relative;
    width: 40px;
    height: 19px;
    box-shadow: 0 0 0 1px var(--main-text-cl);
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -webkit-tap-highlight-color: transparent;

    transition-property: background-color, transform, visibility;
    transition-duration: 0.25s;
    transition-timing-function: ease-in, cubic-bezier(0.6, 0.2, 0.4, 1.5), linear;

    margin: -1px;
    margin-top: 0;

    &:before {
      display: block;
      position: absolute;
      border-radius: 50%;
      content: '';
      top: 2.4px;
      left: 3.2px;
      width: 14px;
      height: 14px;
      background: var(--main-text-cl);

      transition-property: background-color, transform, visibility;
      transition-duration: 0.25s;
      transition-timing-function: ease-in, cubic-bezier(0.6, 0.2, 0.4, 1.5), linear;
    }

    &:focus {
      outline: transparent;
    }

    &:checked:before {
      transform: translateX(19.5px);
    }
  }

  @include maxW(1023px) {
    font-size: 1.6rem;

    span {
      margin-right: 7px;
    }

    p {
      display: block;
      margin-right: 15px;
      cursor: pointer;
    }

    .toggle {
      width: 30px;
      height: 15px;

      margin: 0;

      &:before {
        width: 11px;
        height: 11px;
        top: 2px;
      }

      &:checked:before {
        transform: translateX(13px);
      }
    }
  }
}

.headerMenu {
  display: none;

  @include maxW(1023px) {
    display: flex;
    flex-direction: column;
    padding: 15px 18px;

    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    width: 100%;
    height: var(--app-height);
    background-color: var(--main-bg-cl);
    transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
    overflow: auto;

    opacity: 0;
    visibility: hidden;
    pointer-events: none;

    &.active {
      opacity: 1;
      visibility: visible;
      pointer-events: all;
    }

    .top {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-top: 0 100px;
      text-transform: uppercase;
      font-size: 2.4rem;

      a,p {
        margin-bottom: 10px;
      }
    }

    .bottom {
      .instagram {
        font-size: 1.6rem;
        margin-bottom: 30px;
      }

      .carbon {
        margin-bottom: 7px;
      }

      .carbon,
      p {
        font-size: 1.3rem;
      }
    }
  }
}
